import { storage } from 'react-admin-loopback';

interface LoginParams {
  username: string;
  password: string;
}

export const storage_key = 'lbtoken';

// @see https://marmelab.com/react-admin/Authentication.html
// @see https://github.com/darthwesker/react-admin-loopback/blob/master/src/authProvider.js
export const authProvider = {
  login: ({ username, password }: LoginParams) => {
    const request = new Request('/login', {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ expiration, token }) => {
        const ttl = (expiration - Date.now()) / 1000;
        storage.save(storage_key, { id: `Bearer ${token}` }, ttl);
      });
  },
  logout: () => {
    storage.remove(storage_key);
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = storage.load(storage_key);
    if (token && token.id) {
      return Promise.resolve();
    } else {
      storage.remove('lbtoken');
      return Promise.reject();
    }
  },
  checkError: (error: Error & { status?: number }) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      storage.remove(storage_key);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};
